import { useCallback } from "react";
import getDeepValue from "lodash/get";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import { useFormatDate } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import Check from "../../Form/Check/Form.Check";
import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import InputDate from "../../Form/Input/Input.Date";
import RadioBoolean from "../../Form/Radio/Radio.Boolean";

let propTypes = {
	dateOnlyFlag: PropTypes.bool,
	editFlag: PropTypes.bool,
	name: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	parentName: PropTypes.string,
};

let FormFieldCompletedDate = ({ dateOnlyFlag, editFlag, name, object, objectId, parentName, ...props }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { clearFormValue, formValue, setFormValue } = useFormContext();
	let completedName = parentName ? `${parentName}.completed` : "completed";
	let completedFlag = useFormValue(completedName);
	let completedLabel = translate(
		object ? `defaultFields.edit.${object}.completed` : "displayDetail.fields.boolean.completed"
	);

	let links = formValue?.links;

	let linkIndex = isArray(links) ? links?.findIndex?.((link) => link.objectId === objectId) : -1;

	let onChange = useCallback(
		(e) => {
			if (linkIndex === -1) return;
			let date = formatDate(e, "apiDate");
			let formName = `links[${linkIndex}].completedDate`;
			setFormValue(formName, date);
		},
		[formatDate, linkIndex, setFormValue]
	);

	let onCheckboxChange = useCallback(() => {
		if (completedFlag) {
			clearFormValue(name);
		} else {
			setFormValue(name, formatDate(new Date(), "apiDate"));
		}
	}, [clearFormValue, completedFlag, formatDate, name, setFormValue]);

	return (
		<>
			{!dateOnlyFlag &&
				(editFlag ? (
					<RadioBoolean
						falseText={translate("displayDetail.fields.boolean.notCompleted")}
						label={completedLabel}
						name={completedName}
						trueText={translate("displayDetail.fields.boolean.completed")}
					/>
				) : (
					<Check name={completedName} text={completedLabel} onChange={onCheckboxChange} />
				))}
			{(completedFlag || editFlag || dateOnlyFlag) && (
				<InputDate data-testid="completedDate" name={name} onChange={onChange} noTimeFlag {...props} />
			)}
		</>
	);
};
FormFieldCompletedDate.propTypes = propTypes;
export default FormFieldCompletedDate;
